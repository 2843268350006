const baseUrl = 'https://app.octanise.com';
const secondURL = 'https://app.octanise.com';

const config = {
  dev: false,
  legacyURL: `${baseUrl}`,
  loginURL: `${baseUrl}/api/v1/access/login`,
  refreshURL: `${baseUrl}/api/v1/access/refreshToken`,
  apiURL: `${baseUrl}/api`,
  entitlementURL: `${baseUrl}/api`,
  redirectURL: `${baseUrl}/auth/login`,
  session: {
    resave: false,
    saveUninitialized: false,
    secret: 'pUd7T6qCahkbkpLkVJEjXbJDRAs3XKtVdNpPy9Ph',
    cookie: {
      maxAge: 2592000000,
      domain: 'app.octanise.com',
      secure: 'auto',
      sameSite: 'lax',
    },
    name: 'octanise-session',
    store: null,
    rolling: true,
  },
  awsS3: {
    bucketName: 'p-core-euw1-app.octanise',
    endpoint: 'https://s3-eu-west-1.amazonaws.com/',
  },
  redis: {
    socket: {
      host: 'p-core-euw1-redis-001.p-core-euw1-redis.eyodw3.euw1.cache.amazonaws.com',
      port: 6379,
      tls: true,
    },
    password: 'sZ7qONn5g8kWPqbS2T4h',
    prefix: 'octanise_session:',
    ttl: 2592000
  },
  i18preload: ['en'],
  i18ns: ['common'],
  productPageURL: 'https://www.octanise.com/products',
  interstitialLoaderURL: `${baseUrl}/interstitial`,
  googleRecaptcha: {
    siteKey: '6LeC9cMUAAAAADlVgn5Ylif0cUQ92ZbShEkMZMh-',
  },
  enableIntercom: false,
  bugsnag: {
    apiKey: '0e0d68150099c42ef0eb62a7ae2eac83',
  },
  pictureResize: {
    site: {
      height: 200,
      width: 200,
    },
    asset: {
      height: 200,
      width: 200,
    },
  },
  syncfusion: {
    licenseKey: 'ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5Vd0FjXntZdHVTTmde',
  },	
};
module.exports = config;
